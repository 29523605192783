import initMatrix from "../initMatrix";
import cons from "../state/cons";

const waitForInitMatrix = async () => {
  const checkInterval = 100;
  const maxRetries = 50;
  let retries = 0;

  while (
    (!initMatrix.roomList || !initMatrix.notifications) &&
    retries < maxRetries
  ) {
    await new Promise((resolve) => setTimeout(resolve, checkInterval));
    retries += 1;
  }

  if (!initMatrix.roomList || !initMatrix.notifications) {
    throw new Error("initMatrix objects not initialized in time");
  }
};

const totalInviteCount = () => {
  const count =
    initMatrix.roomList.inviteRooms.size +
    initMatrix.roomList.inviteSpaces.size +
    initMatrix.roomList.inviteDirects.size;
  return count;
};

function getHomeNoti() {
  try {
    const orphans = initMatrix.roomList.getOrphans();
    let noti = null;

    orphans.forEach((roomId) => {
      if (!initMatrix.notifications.hasNoti(roomId)) {
        return;
      }
      if (noti === null) noti = { total: 0, highlight: 0 };
      const childNoti = initMatrix.notifications.getNoti(roomId);
      noti.total += childNoti.total;
      noti.highlight += childNoti.highlight;
    });

    return noti;
  } catch (error) {
    console.error("Error in getHomeNoti:", error);
    return null;
  }
}

function getDMsNoti() {
  try {
    if (initMatrix.roomList.directs.size === 0) {
      return null;
    }
    let noti = null;

    [...initMatrix.roomList.directs].forEach((roomId) => {
      if (!initMatrix.notifications.hasNoti(roomId)) {
        return;
      }
      if (noti === null) noti = { total: 0, highlight: 0 };
      const childNoti = initMatrix.notifications.getNoti(roomId);
      noti.total += childNoti.total;
      noti.highlight += childNoti.highlight;
    });

    return noti;
  } catch (error) {
    return null;
  }
}

export async function totalNotifications(reset = false) {
  if (reset) {
    initMatrix.notifications.sendTotalNotifications(0);
    return 0;
  }

  const homeNoti = getHomeNoti();
  const dmsNoti = getDMsNoti();
  const totalInvites = totalInviteCount();

  const homeTotal = homeNoti ? homeNoti.total : 0;
  const dmsTotal = dmsNoti ? dmsNoti.total : 0;
  const totalCount = homeTotal + dmsTotal + totalInvites;

  initMatrix.notifications.sendTotalNotifications(totalCount);

  return totalCount;
}

export const toggleMute = async (roomId) => {
  const mx = initMatrix.matrixClient;
  const { notifications } = initMatrix;
  const isMuted = notifications.getNotiType(roomId) === cons.notifs.MUTE;

  let roomPushRule;
  try {
    const pushRules = await mx.getPushRules();
    roomPushRule = pushRules.global.override.find(
      (rule) => rule.rule_id === roomId
    );
  } catch (error) {
    roomPushRule = undefined;
  }

  if (isMuted) {
    try {
      if (roomPushRule) {
        await mx.deletePushRule("global", "override", roomPushRule.rule_id);
      }
    } catch (error) {
      console.error(
        `Failed to delete override push rule for room ${roomId}:`,
        error
      );
    }
  } else {
    try {
      await mx.addPushRule("global", "override", roomId, {
        conditions: [
          {
            kind: "event_match",
            key: "room_id",
            pattern: roomId,
          },
        ],
        actions: ["dont_notify"],
      });
    } catch (error) {
      console.error(
        `Failed to add override push rule for room ${roomId}:`,
        error
      );
    }
  }

  try {
    await mx.getPushRules();
  } catch (error) {
    console.error("Failed to fetch push rules:", error);
  }
};

export const isRoomMuted = (roomId) => {
  const noti = initMatrix.notifications;
  return noti.getNotiType(roomId) === cons.notifs.MUTE;
};

export async function markAsRead(roomId) {
  const mx = initMatrix.matrixClient;
  const room = mx.getRoom(roomId);
  if (!room) return;
  initMatrix.notifications.deleteNoti(roomId);
  const timeline = room.getLiveTimeline().getEvents();
  const readEventId = room.getEventReadUpTo(mx.getUserId());

  const getLatestValidEvent = () => {
    for (let i = timeline.length - 1; i >= 0; i -= 1) {
      const latestEvent = timeline[i];
      if (latestEvent.getId() === readEventId) return null;
      if (!latestEvent.isSending()) return latestEvent;
    }
    return null;
  };
  if (timeline.length === 0) return;
  const latestEvent = getLatestValidEvent();
  if (latestEvent === null) return;

  await mx.sendReadReceipt(latestEvent);
}
