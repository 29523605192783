import React from "react";
import "./Navbar.scss";
import LeftSideContainer from "./LeftSideContainer";
import RightSideContainer from "./RightSideContainer";
import SearchBarMobile from "./SearchBarMobile";

function Navbar({ mx, matrixUser }) {
  return (
    <div className="navbar-container">
      <div className="navbar-wrapper">
        <LeftSideContainer />
        <RightSideContainer mx={mx} matrixUser={matrixUser}/>
      </div>
      <SearchBarMobile />
    </div>
  );
}

export default Navbar;
