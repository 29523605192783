import { incrementActionCount } from "../client/referrals/api";
import cons from "../client/state/cons";

const ACTION_POINTS = {
  message: 1,
  reaction: 1,
  referral: 20,
  create_account: 0,
  daily_active_user: 0,
  create_space: 20,
  create_presale: 20,
  finalize_presale: 20,
  cast_vote: 3,
  create_proposal: 5,
  swap: 3,
  create_room: 2,
};

const RATE_LIMIT_INTERVALS = {
  message: 10 * 60 * 1000,
  reaction: 10 * 60 * 1000,
  create_proposal: 24 * 60 * 60 * 1000,
  create_room: 24 * 60 * 60 * 1000,
};

const EVENT_TYPE = "my.custom.community.scores";

export async function updateCommunityMembershipScore(
  mxClient,
  actionType,
  spaceId = null,
  useStateKey = false
) {
  if (import.meta.env.VITE_ENVIRONMENT === "development") {
    return;
  }
  const points = ACTION_POINTS[actionType] || 0;
  const accountDataEvent = mxClient.getAccountData(EVENT_TYPE);
  const accountDataRaw = accountDataEvent
    ? accountDataEvent.getContent()
    : null;
  const stateKey = mxClient.getUserId();
  const accessToken = localStorage.getItem(cons.secretKey.ACCESS_TOKEN);
  const accountData = accountDataRaw?.communityMembershipScores || {
    individualScore: { total: 0, actions: [], lastActionTimestamps: {} },
    spaces: {},
  };
  const currentTime = Date.now();
  let isRateLimited = false;

  if (RATE_LIMIT_INTERVALS[actionType]) {
    const rateLimitInterval = RATE_LIMIT_INTERVALS[actionType];

    if (!accountData.individualScore.lastActionTimestamps) {
      accountData.individualScore.lastActionTimestamps = {};
    }
    const lastIndividualActionTimestamp =
      accountData.individualScore.lastActionTimestamps[actionType] || 0;

    if (currentTime - lastIndividualActionTimestamp < rateLimitInterval) {
      isRateLimited = true;
    } else {
      accountData.individualScore.lastActionTimestamps[actionType] =
        currentTime;
    }

    if (spaceId) {
      if (!accountData.spaces[spaceId]) {
        accountData.spaces[spaceId] = {
          total: 0,
          actions: [],
          lastActionTimestamps: {},
        };
      }
      if (!accountData.spaces[spaceId].lastActionTimestamps) {
        accountData.spaces[spaceId].lastActionTimestamps = {};
      }
      const lastSpaceActionTimestamp =
        accountData.spaces[spaceId].lastActionTimestamps[actionType] || 0;

      if (currentTime - lastSpaceActionTimestamp < rateLimitInterval) {
        isRateLimited = true;
      } else {
        accountData.spaces[spaceId].lastActionTimestamps[actionType] =
          currentTime;
      }
    }
  }
  if (isRateLimited) {
    return;
  }
  if (spaceId) {
    if (!accountData.spaces[spaceId]) {
      accountData.spaces[spaceId] = { total: 0, actions: [] };
    }
    accountData.spaces[spaceId].total += points;
    accountData.spaces[spaceId].actions.push({
      type: actionType,
      timestamp: currentTime,
      points,
    });
    const content = {
      score: accountData.spaces[spaceId].total,
    };
    await mxClient.sendStateEvent(spaceId, EVENT_TYPE, content, stateKey);
  }

  accountData.individualScore.total += points;
  accountData.individualScore.actions.push({
    type: actionType,
    timestamp: currentTime,
    points,
  });

  try {
    await mxClient.setAccountData(EVENT_TYPE, {
      communityMembershipScores: accountData,
    });
    await incrementActionCount(actionType, stateKey, accessToken, useStateKey);
  } catch (error) {
    throw error;
  }
}

export function getCommunityMembershipScore(
  mxClient,
  spaceId = null,
  actionType = null
) {
  const accountDataEvent = mxClient.getAccountData(EVENT_TYPE);
  const accountDataRaw = accountDataEvent
    ? accountDataEvent.getContent()
    : null;
  const accountData = accountDataRaw?.communityMembershipScores;

  if (!accountData) {
    return { totalScore: 0, actionScore: 0 };
  }

  let totalScore = 0;
  let actionCount = 0; // Changed from actionScore to actionCount

  if (spaceId) {
    const spaceData = accountData.spaces[spaceId];
    if (spaceData && spaceData.total != null) {
      totalScore = spaceData.total;

      if (actionType) {
        spaceData.actions.forEach((action) => {
          if (action.type === actionType) {
            actionCount++; // Increment count instead of accumulating points
          }
        });
      }
    }
  } else {
    totalScore = accountData.individualScore?.total || 0;
    if (actionType && accountData.individualScore?.actions) {
      accountData.individualScore.actions.forEach((action) => {
        if (action.type === actionType) {
          actionCount++; // Increment count instead of accumulating points
        }
      });
    }
  }

  return { totalScore, actionScore: actionCount }; // Return the count as actionScore
}

export function getCommunityScoresInSpace(mxClient, spaceId, userId) {
  const room = mxClient.getRoom(spaceId);
  if (!room) {
    return 0;
  }

  const stateEvent = room.currentState.getStateEvents(EVENT_TYPE, userId);
  if (!stateEvent) {
    return 0;
  }

  const content = stateEvent.getContent();
  const score = content.score || 0;
  return score;
}
