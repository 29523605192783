import React, { useEffect, useState } from "react";
import EventItem from "./EventItem";
import Divider from "../../atoms/divider/Divider";
import { isInSameDay } from "../../../util/common";
import dateFormat from "dateformat";
import { useMessagingContext } from "../../contexts/MessagingProviderContext";
import initMatrix from "../../../client/initMatrix";

const TimelineEventWrapper = ({
  event,
  lastReadEventId,
  events,
  showDivider,
  eventContext,
  containerRef,
  room,
  roomId,
  loadMoreEvents,
  isKeyboardVisible,
  timeline,
  reactions,
  fetchReactions,
  mx,
}) => {
  const { messageId, actionType, setEventAction } = useMessagingContext();
  const isLastEvent = event.getId() === events[events.length - 1]?.getId();
  const currentEventDate = event.getDate();
  const eventIndex = events.findIndex((e) => e.getId() === event.getId());
  const prevEvent = eventIndex > 0 ? events[eventIndex - 1] : null;
  const prevEventDate = prevEvent?.getDate() ?? null;
  const senderId = event.getSender();
  const isCurrentMessenger = senderId === initMatrix.matrixClient.getUserId();
  const isRedacted = !!event.event.unsigned?.redacted_because;
  const isEditedMessageFallback = event.getContent()?.body?.startsWith("*");

  if (isRedacted || isEditedMessageFallback) {
    return null;
  }

  const handleScrollAndFocus = (eventElement, container) => {
    const containerRect = container.getBoundingClientRect();
    const eventRect = eventElement.getBoundingClientRect();

    const scrollPosition =
      eventRect.top -
      containerRect.top +
      container.scrollTop -
      containerRect.height / 2 +
      eventRect.height / 2;

    if (actionType === "scroll" || actionType === "both") {
      container.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
    setEventAction(null, null);
  };

  const attemptScrollAndFocus = async () => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    let eventElement = document.getElementById(messageId);

    if (!eventElement) {
      await loadMoreEvents();
      eventElement = document.getElementById(messageId);
    }

    if (eventElement) {
      setTimeout(() => {
        handleScrollAndFocus(eventElement, container);
      }, 20);
    }
  };

  useEffect(() => {
    if (!messageId || !isLastEvent || !containerRef.current) return;
    attemptScrollAndFocus();
  }, [messageId, isLastEvent, containerRef, loadMoreEvents]);

  return (
    <>
      {prevEventDate &&
        currentEventDate &&
        !isInSameDay(currentEventDate, prevEventDate) &&
        eventIndex > 0 && (
          <Divider
            key={`divider-${event.getId()}`}
            text={`${dateFormat(currentEventDate, "mmmm dd, yyyy")}`}
          />
        )}
      <EventItem
        event={event}
        id={event.getId()}
        eventContext={eventContext}
        prevEvent={prevEvent}
        roomId={roomId}
        room={room}
        isKeyboardVisible={isKeyboardVisible}
        isCurrentMessenger={isCurrentMessenger}
        timeline={timeline}
        mx={mx}
        reactions={reactions}
        fetchReactions={fetchReactions}
      />
      {showDivider &&
        event.getId() === lastReadEventId &&
        event.getId() !== events[events.length - 1]?.getId() && (
          <Divider
            key={`new-${event.getId()}`}
            variant="positive"
            text="Unread messages"
          />
        )}
    </>
  );
};

export default TimelineEventWrapper;
