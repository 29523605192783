import EventEmitter from "events";
import cons from "./cons";
import axios from "axios";
import { fetchInitialTimeline } from "../../app/organisms/room/roomTimelineUtils";

function isNotifEvent(mEvent) {
  const eType = mEvent.getType();
  if (!cons.supportEventTypes.includes(eType)) return false;
  if (eType === "m.room.member") return false;

  if (mEvent.isRedacted()) return false;
  if (mEvent.getRelation()?.rel_type === "m.replace") return false;

  return true;
}

function isMutedRule(rule) {
  return (
    rule.actions[0] === "dont_notify" &&
    rule.conditions[0].kind === "event_match"
  );
}

function findMutedRule(overrideRules, roomId) {
  return overrideRules.find(
    (rule) => rule.rule_id === roomId && isMutedRule(rule)
  );
}

class Notifications extends EventEmitter {
  constructor(roomList) {
    super();

    this.initialized = false;
    this.matrixClient = roomList.matrixClient;
    this.roomList = roomList;
    this.roomIdToNoti = new Map();
    this.roomIdToPopupNotis = new Map();
    this.eventIdToPopupNoti = new Map();
    this.setMaxListeners(5000);
    this._listenEvents();
    window.Notification?.requestPermission();
  }

  async initNoti() {
    this.initialized = false;
    this.roomIdToNoti = new Map();

    const addNoti = async (roomId) => {
      try {
        const room = this.matrixClient.getRoom(roomId);
        await fetchInitialTimeline(this.matrixClient, roomId);
        const notiType = this.getNotiType(room.roomId);
        if (notiType === cons.notifs.MUTE) {
          return;
        }
        const hasUnread = await this.calculateUnreadMessages(room);
        if (!hasUnread || hasUnread.total === 0) {
          return;
        }
        const total = hasUnread.total;
        const highlight = room.notificationCounts
          ? room.notificationCounts.highlight
          : 0;
        this._setNoti(room.roomId, total, highlight);
      } catch (error) {
        console.error(`TESTING - Error processing room ${roomId}:`, error);
      }
    };

    const roomIds = [...this.roomList.rooms, ...this.roomList.directs];
    const addNotiPromises = roomIds.map((roomId) => addNoti(roomId));
    await Promise.all(addNotiPromises);
    this.initialized = true;
  }

  async calculateUnreadMessages(room) {
    try {
      // Retrieve the current user's ID
      const userId = this.matrixClient.getUserId();
      console.log("TESTING: Retrieved userId:", userId);

      // Get the event ID up to which the user has read
      const readUpToEventId = room.getEventReadUpTo(userId);
      console.log("TESTING: readUpToEventId:", readUpToEventId);

      // Get all events from the live timeline
      const events = room.getLiveTimeline().getEvents();
      console.log("TESTING: Retrieved events:", events);

      // Check if there are no events
      if (!events || events.length === 0) {
        console.log("TESTING: No events found in the timeline.");
        return { total: 0, highlight: 0 };
      }

      // Initialize readUpToIndex
      let readUpToIndex = -1;
      console.log("TESTING: Starting search for readUpToEventId in events.");

      // Find the index of the readUpToEventId in the events list
      for (let i = events.length - 1; i >= 0; i--) {
        const event = events[i];
        console.log(
          `TESTING: Checking event at index ${i} with ID ${event.getId()}`
        );
        if (event.getId() === readUpToEventId) {
          readUpToIndex = i;
          console.log(`TESTING: Found readUpToEventId at index ${i}`);
          break;
        }
      }

      // Calculate total events and initialize unread count
      const totalEvents = events.length;
      console.log("TESTING: Total number of events:", totalEvents);
      let totalUnread = 0;

      // Determine the starting index for unread messages
      const startIndex = readUpToIndex === -1 ? 0 : readUpToIndex + 1;
      console.log(
        "TESTING: Calculating unread messages starting from index:",
        startIndex
      );

      // Iterate over events after the readUpToIndex
      for (let i = startIndex; i < events.length; i++) {
        const event = events[i];
        console.log(
          `TESTING: Processing event at index ${i} with type ${event.getType()}`
        );

        // Only count events of type "m.room.message"
        if (event.getType() === "m.room.message") {
          totalUnread++;
          console.log(`TESTING: Incremented totalUnread to ${totalUnread}`);
        } else {
          console.log(`TESTING: Ignored event of type ${event.getType()}`);
        }
      }

      console.log(
        `TESTING: Total unread messages of type "m.room.message": ${totalUnread}`
      );
      return { total: totalUnread, highlight: 0 }; // Adjust 'highlight' as needed
    } catch (error) {
      console.error("TESTING: Error calculating unread messages:", error);
      return { total: 0, highlight: 0 };
    }
  }

  doesRoomHaveUnread(room) {
    const userId = this.matrixClient.getUserId();
    const readUpToId = room.getEventReadUpTo(userId);
    const liveEvents = room.getLiveTimeline().getEvents();

    if (liveEvents[liveEvents.length - 1]?.getSender() === userId) {
      return false;
    }

    for (let i = liveEvents.length - 1; i >= 0; i -= 1) {
      const event = liveEvents[i];
      if (event.getId() === readUpToId) return false;
      if (isNotifEvent(event)) return true;
    }
    return true;
  }

  getNotiType(roomId) {
    let pushRule;
    try {
      pushRule = this.matrixClient.getRoomPushRule("global", roomId);
    } catch {
      pushRule = undefined;
    }

    if (pushRule === undefined) {
      const overrideRules = this.matrixClient
        .getAccountData("m.push_rules")
        ?.getContent()?.global?.override;
      if (overrideRules === undefined) return cons.notifs.DEFAULT;

      const isMuted = findMutedRule(overrideRules, roomId);

      return isMuted ? cons.notifs.MUTE : cons.notifs.DEFAULT;
    }
    if (pushRule.actions[0] === "notify") return cons.notifs.ALL_MESSAGES;
    return cons.notifs.MENTIONS_AND_KEYWORDS;
  }

  getNoti(roomId) {
    return (
      this.roomIdToNoti.get(roomId) || { total: 0, highlight: 0, from: null }
    );
  }

  getTotalNoti(roomId) {
    const { total } = this.getNoti(roomId);
    return total;
  }

  getHighlightNoti(roomId) {
    const { highlight } = this.getNoti(roomId);
    return highlight;
  }

  getFromNoti(roomId) {
    const { from } = this.getNoti(roomId);
    return from;
  }

  hasNoti(roomId) {
    return this.roomIdToNoti.has(roomId);
  }

  deleteNoti(roomId) {
    if (this.hasNoti(roomId)) {
      const noti = this.getNoti(roomId);
      this._deleteNoti(roomId, noti.total, noti.highlight);
    }
  }

  async sendTotalNotifications(totalCount) {
    const deviceToken = localStorage.getItem("devicePushKey");
    const sygnalServer = import.meta.env.VITE_SYGNAL_SERVER_URL;
    const platform = Capacitor.getPlatform();
    const appId =
      platform === "ios" ? "app.waivlength" : "app.waivlength.android";

    const notificationPayload = {
      notification: {
        devices: [
          {
            pushkey: deviceToken,
            app_id: appId,
            data: {
              body: "Silent Notification",
              total_count: totalCount,
            },
          },
        ],
      },
    };

    try {
      axios
        .post(`${sygnalServer}/_matrix/push/v1/notify`, notificationPayload)
        .then((response) => {})
        .catch((error) => {
          console.error(
            "Error sending silent notification:",
            error.response.data
          );
        });
    } catch (error) {
      console.error("Error sending silent notification:", error);
    }
  }

  _setNoti(roomId, total, highlight) {
    const addNoti = (id, t, h, fromId) => {
      const prevTotal = this.roomIdToNoti.get(id)?.total ?? null;
      const noti = this.getNoti(id);

      noti.total += t;
      noti.highlight += h;

      if (fromId) {
        if (noti.from === null) noti.from = new Set();
        noti.from.add(fromId);
      }
      this.roomIdToNoti.set(id, noti);
      this.emit(
        cons.events.notifications.NOTI_CHANGED,
        id,
        noti.total,
        prevTotal
      );
    };

    const noti = this.getNoti(roomId);
    const addT = (highlight > total ? highlight : total) - noti.total;
    const addH = highlight - noti.highlight;
    if (addT < 0 || addH < 0) return;

    addNoti(roomId, addT, addH);

    const allParentSpaces = this.roomList.getAllParentSpaces(roomId);
    allParentSpaces.forEach((spaceId) => {
      addNoti(spaceId, addT, addH, roomId);
    });
  }

  _deleteNoti(roomId, total, highlight) {
    const removeNoti = (id, t, h, fromId) => {
      if (this.roomIdToNoti.has(id) === false) {
        return;
      }

      const noti = this.getNoti(id);

      const prevTotal = noti.total;
      noti.total -= t;
      noti.highlight -= h;
      if (noti.total < 0) {
        noti.total = 0;
        noti.highlight = 0;
      }

      if (fromId && noti.from !== null) {
        if (!this.hasNoti(fromId)) noti.from.delete(fromId);
      }
      if (noti.from === null || noti.from.size === 0) {
        this.roomIdToNoti.delete(id);
        this.emit(cons.events.notifications.FULL_READ, id);
        this.emit(cons.events.notifications.NOTI_CHANGED, id, null, prevTotal);
      } else {
        this.roomIdToNoti.set(id, noti);
        this.emit(
          cons.events.notifications.NOTI_CHANGED,
          id,
          noti.total,
          prevTotal
        );
      }
    };

    removeNoti(roomId, total, highlight);
    const allParentSpaces = this.roomList.getAllParentSpaces(roomId);
    allParentSpaces.forEach((spaceId) => {
      removeNoti(spaceId, total, highlight, roomId);
    });

    this.sendTotalNotifications();
  }

  _deletePopupNoti(eventId) {
    this.eventIdToPopupNoti.get(eventId)?.close();
    this.eventIdToPopupNoti.delete(eventId);
  }

  _deletePopupRoomNotis(roomId) {
    this.roomIdToPopupNotis.get(roomId)?.forEach((n) => {
      this.eventIdToPopupNoti.delete(n.tag);
      n.close();
    });
    this.roomIdToPopupNotis.delete(roomId);
  }

  _listenEvents() {
    this.matrixClient.on("Room.timeline", (mEvent, room) => {
      if (mEvent.isRedaction()) this._deletePopupNoti(mEvent.event.redacts);

      if (room.isSpaceRoom()) return;
      if (!isNotifEvent(mEvent)) return;

      const liveEvents = room.getLiveTimeline().getEvents();

      const lastTimelineEvent = liveEvents[liveEvents.length - 1];
      if (lastTimelineEvent.getId() !== mEvent.getId()) return;
      if (mEvent.getSender() === this.matrixClient.getUserId()) return;

      const total = room.getUnreadNotificationCount("total");
      const highlight = room.getUnreadNotificationCount("highlight");

      if (this.getNotiType(room.roomId) === cons.notifs.MUTE) {
        this.deleteNoti(room.roomId, total ?? 0, highlight ?? 0);
        return;
      }

      this._setNoti(room.roomId, total ?? 0, highlight ?? 0);
    });

    this.matrixClient.on("accountData", (mEvent, oldMEvent) => {
      if (mEvent.getType() === "m.push_rules") {
        const override = mEvent?.getContent()?.global?.override;
        const oldOverride = oldMEvent?.getContent()?.global?.override;
        if (!override || !oldOverride) return;

        const isMuteToggled = (rule, otherOverride) => {
          const roomId = rule.rule_id;
          const room = this.matrixClient.getRoom(roomId);
          if (room === null) return false;
          if (room.isSpaceRoom()) return false;

          const isMuted = isMutedRule(rule);
          if (!isMuted) return false;
          const isOtherMuted = findMutedRule(otherOverride, roomId);
          if (isOtherMuted) return false;
          return true;
        };

        const mutedRules = override.filter((rule) =>
          isMuteToggled(rule, oldOverride)
        );
        const unMutedRules = oldOverride.filter((rule) =>
          isMuteToggled(rule, override)
        );

        mutedRules.forEach((rule) => {
          this.emit(cons.events.notifications.MUTE_TOGGLED, rule.rule_id, true);
          this.deleteNoti(rule.rule_id);
        });
        unMutedRules.forEach((rule) => {
          this.emit(
            cons.events.notifications.MUTE_TOGGLED,
            rule.rule_id,
            false
          );
          const room = this.matrixClient.getRoom(rule.rule_id);
          if (!this.doesRoomHaveUnread(room)) return;
          const total = room.getUnreadNotificationCount("total");
          const highlight = room.getUnreadNotificationCount("highlight");
          this._setNoti(room.roomId, total ?? 0, highlight ?? 0);
        });
      }
    });

    this.matrixClient.on("Room.receipt", (mEvent, room) => {
      if (mEvent.getType() === "m.receipt") {
        if (room.isSpaceRoom()) return;
        const content = mEvent.getContent();
        const readedEventId = Object.keys(content)[0];
        const readerUserId = Object.keys(content[readedEventId]["m.read"])[0];
        if (readerUserId !== this.matrixClient.getUserId()) return;

        this.deleteNoti(room.roomId);
        this._deletePopupRoomNotis(room.roomId);
      }
    });

    this.matrixClient.on("Room.myMembership", (room, membership) => {
      if (membership === "leave" && this.hasNoti(room.roomId)) {
        this.deleteNoti(room.roomId);
      }
    });
  }
}

export default Notifications;
