const INITIAL_LIMIT = 30;

export const fetchInitialTimeline = async (client, roomId) => {
  const room = client.getRoom(roomId);
  if (!room) {
    throw new Error("Room not found");
  }
  const timeline = room.getLiveTimeline();
  const events = timeline.getEvents();
  let allEvents = [...events];
  let canPaginate = room.oldState.paginationToken !== null; // This controls whether pagination can continue

  const addUniqueEvents = (newEvents, existingEvents) => {
    const uniqueEvents = newEvents.filter(
      (event) =>
        !existingEvents.some(
          (existingEvent) => existingEvent.getId() === event.getId()
        )
    );
    return [...uniqueEvents, ...existingEvents];
  };

  while (canPaginate && allEvents.length < INITIAL_LIMIT) {
    await client.scrollback(room, 50);
    const newEvents = timeline.getEvents();
    allEvents = addUniqueEvents(newEvents, allEvents);
    allEvents.sort((a, b) => a.getTs() - b.getTs());

    const createEventIndex = allEvents.findIndex(
      (event) => event.getType() === "m.room.create"
    );
    if (createEventIndex !== -1) {
      allEvents = allEvents.slice(createEventIndex);
    }

    // Ensure the paginationToken is updated here
    canPaginate = room.oldState.paginationToken !== null;
  }

  return {
    timeline,
    events: allEvents,
  };
};

export const fetchMoreEvents = async (
  client,
  room,
  currentEvents,
  limit,
  timeline
) => {
  if (!room || !timeline) {
    throw new Error("Room or timeline not found");
  }
  console.log("TESTING - Room and timeline found:", { room, timeline });

  let canPaginate = room.oldState.paginationToken !== null;
  console.log("TESTING - Initial canPaginate value:", canPaginate);

  if (!canPaginate) {
    console.log(
      "TESTING - No more events to paginate, returning current events."
    );
    return { events: currentEvents, timeline };
  }

  let allEvents = [...currentEvents];
  console.log("TESTING - Initial allEvents length:", allEvents.length);

  const addUniqueEvents = (newEvents, existingEvents) => {
    const uniqueEvents = newEvents.filter(
      (event) =>
        !existingEvents.some(
          (existingEvent) => existingEvent.getId() === event.getId()
        )
    );
    return [...uniqueEvents, ...existingEvents];
  };

  try {
    console.log("TESTING - Paginating timeline with limit:", limit);
    await client.paginateEventTimeline(timeline, {
      backwards: true,
      limit: limit,
    });

    const newEvents = timeline.getEvents();
    console.log("TESTING - New events fetched:", newEvents.length);

    allEvents = addUniqueEvents(newEvents, allEvents);
    console.log(
      "TESTING - Updated allEvents length after filtering:",
      allEvents.length
    );

    allEvents.sort((a, b) => a.getTs() - b.getTs());
    console.log("TESTING - All events sorted by timestamp.");

    const createEventIndex = allEvents.findIndex(
      (event) => event.getType() === "m.room.create"
    );
    if (createEventIndex !== -1) {
      allEvents = allEvents.slice(createEventIndex);
      console.log(
        "TESTING - Events sliced from creation event, new length:",
        allEvents.length
      );
    }

    canPaginate = room.oldState.paginationToken !== null;
    console.log("TESTING - Updated canPaginate value:", canPaginate);
  } catch (error) {
    console.error("TESTING - Failed to paginate:", error);
  }

  console.log(
    "TESTING - Returning all events with final length:",
    allEvents.length
  );
  return {
    events: allEvents,
    timeline,
  };
};

export const listenToLiveEvents = (client, roomId, onEventReceived) => {
  const room = client.getRoom(roomId);
  if (!room) {
    throw new Error("Room not found");
  }

  const handleLiveEvent = (event) => {
    if (event.getRoomId() === roomId) {
      onEventReceived(event);
    }
  };

  client.on("Room.timeline", handleLiveEvent);

  return () => {
    client.removeListener("Room.timeline", handleLiveEvent);
  };
};
