import React, { useEffect, useState } from "react";
import ClientContent from "../client-content/ClientContent";
import SideBar from "../../organisms/navigation/SideBar";
import Navbar from "../../components/navbar/Navbar";
import SpaceViewWrapper from "../../organisms/space-view/SpaceViewWrapper";
import { useRoomSelect } from "../../contexts/RoomSelectContext";
import Room from "../../organisms/room/Room";
import "./ConnectedContent.scss";
import { useDiscoverSpacesSales } from "../../contexts/DiscoverSpacesSalesContext";
import { registerDevice } from "../../../util/pushNotificationSetup";
import { PushNotifications } from "@capacitor/push-notifications";
import useNotificationHandler from "../../hooks/useNotificationHandler";
import { totalNotifications } from "../../../client/action/notifications";
import { App } from "@capacitor/app";
import { updateCommunityMembershipScore } from "../../../util/membershipScore";

const ConnectedContent = ({ isAppConnected, mx }) => {
  const { roomId, dmRoomId, spaceId, setSpaceId, space } = useRoomSelect();
  const { spaces, saleRooms } = useDiscoverSpacesSales();
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { handleNotification } = useNotificationHandler();
  const matrixUser = mx.getUser(mx.getUserId());

  useEffect(() => {
    const initializePushNotifications = async () => {
      try {
        await registerDevice();
        await updateCommunityMembershipScore(mx, "daily_active_user", null, true);
      } catch (error) {
        console.error(
          "Error registering device for push notifications:",
          error
        );
      }
    };

    initializePushNotifications();
    return () => {
      PushNotifications.removeAllListeners();
    };
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      finalizeClose();
    }, 300);
  };

  const finalizeClose = () => {
    setSpaceId(null);
    setIsOpen(false);
    setIsClosing(false);
  };

  useEffect(() => {
    document.body.classList.add("padding-bottom-active");
    return () => {
      document.body.classList.remove("padding-bottom-active");
    };
  }, [isAppConnected]);

  useEffect(() => {
    const handleAppStateChange = async (state) => {
      if (state.isActive) {
        await totalNotifications();
      }
    };

    App.addListener("appStateChange", handleAppStateChange);

    return () => {
      App.removeListener("appStateChange", handleAppStateChange);
    };
  }, []);

  useEffect(() => {
    if (space && spaceId) {
      setIsOpen(true);
      document
        .querySelector(".connected-container-wrapper")
        .classList.add("container-right");
    } else {
      if (!isClosing) {
        setIsOpen(false);
        document
          .querySelector(".connected-container-wrapper")
          .classList.remove("container-right");
      }
    }
  }, [spaceId, isClosing, space]);

  useEffect(() => {
    if (isClosing) {
      document
        .querySelector(".connected-container-wrapper")
        .classList.remove("container-right");
    }
  }, [isClosing]);

  useEffect(() => {
    const addNotificationListeners = async () => {
      await PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          const roomId = notification.notification.data.room_id;
          const eventId = notification.notification.data.event_id;
          const isInvite = notification.notification.data.invite === "invite";
          try {
            setTimeout(() => {
              handleNotification(roomId, isInvite, eventId);
            }, 100);
          } catch (error) {
            console.error("Error handling notification:", error);
          }
        }
      );
    };

    addNotificationListeners();

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [handleNotification]);

  return (
    <>
      <div className="connected-container-wrapper">
        <Navbar mx={mx} matrixUser={matrixUser} />
        <div className="client-content-wrapper">
          <ClientContent
            dmRoomId={dmRoomId}
            spaces={spaces}
            saleRooms={saleRooms}
          />
        </div>
        <SideBar />
      </div>
      <div className={`room__wrapper ${roomId ? "" : "room-hidden"}`}>
        <Room roomId={roomId} />
      </div>
      {spaceId && space && (
        <SpaceViewWrapper
          isOpen={isOpen}
          isClosing={isClosing}
          onClose={handleClose}
          spaceId={spaceId}
          space={space}
        />
      )}
    </>
  );
};

export default ConnectedContent;
