import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import ERC20 from "@openzeppelin/contracts/build/contracts/ERC20.json";
import "./Swap.scss";
import {
  calculateGasFee,
  calculateOutputAmount,
} from "../../../util/calculateQuote";
import { isValidValue } from "../../../util/valueChecker";
import SwapReview from "./SwapReview";
import { useAccount } from "wagmi";
import Modal from "../../atoms/modal/Modal";
import SwapSettings from "./SwapSettings";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";
import { ReactComponent as Warning } from "../../assets/svg/fillwarning.svg";
import { ReactComponent as Gas } from "../../assets/svg/gas-station.svg";
import { ReactComponent as Eth } from "../../assets/svg/eth.svg";
import CustomKeyboard from "./CustomKeyboard";
import RoomAvatar from "../../atoms/avatar/RoomAvatar";
import DynamicFontSizeInput from "./DynamicFontSizeInput";

const Swap = ({
  liquidityData,
  provider,
  setIsModalOpen,
  isModalOpen,
  avatarUrl,
  spaceId,
}) => {
  const [tokenIn, setTokenIn] = useState("ETH");
  const [tokenOut, setTokenOut] = useState(liquidityData.token0.symbol);
  const [tokenInAddress, setTokenInAddress] = useState(
    liquidityData.token1.address
  );
  const [tokenOutAddress, setTokenOutAddress] = useState(
    liquidityData.token0.address
  );
  const [amountIn, setAmountIn] = useState("");
  const [amountInUnformatted, setAmountInUnformatted] = useState("");
  const [amountOut, setAmountOut] = useState("");
  const [amountInUsd, setAmountInUsd] = useState("");
  const [amountOutUsd, setAmountOutUsd] = useState("");
  const [balanceIn, setBalanceIn] = useState();
  const [balanceOut, setBalanceOut] = useState(0);
  const [swapReview, setSwapReview] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [gasFee, setGasFee] = useState("");
  const [reviewSwapDialog, setReviewSwapDialog] = useState(false);
  const { address: accountAddress } = useAccount();
  const address =
    accountAddress || localStorage.getItem("waivlength_wallet_address");
  const [slippageTolerance, setSlippageTolerance] = useState(5);
  const [inputField, setInputField] = useState(1);
  const [amountExecutableIn, setAmountExecutableIn] = useState("");
  const [amountExecutableOut, setAmountExecutableOut] = useState("");
  const [inputChanged, setInputChanged] = useState(false);
  const [isSwapReviewModalOpen, setIsSwapReviewModalOpen] = useState(false);
  const poolFee = 3000;
  const isEnabled = !swapReview && amountIn != null && amountIn > 0 && amountIn;

  useEffect(() => {
    const storedSlippage = localStorage.getItem("waivlength_slippage");
    if (storedSlippage) {
      setSlippageTolerance(parseFloat(storedSlippage));
    }
  }, []);

  const handleSlippageChange = (newSlippage) => {
    setSlippageTolerance(newSlippage);
    localStorage.setItem("waivlength_slippage", newSlippage);
  };

  useEffect(() => {
    const fetchBalances = async () => {
      try {
        const ethBalanceWei = await provider.getBalance(address);
        const ethBalanceEther = parseFloat(
          ethers.formatEther(ethBalanceWei)
        ).toFixed(2);
        setBalanceIn(ethBalanceEther);

        const tokenContract = new ethers.Contract(
          tokenOutAddress,
          ERC20.abi,
          provider
        );
        const tokenBalance = await tokenContract.balanceOf(address);
        const tokenBalanceFormatted = parseFloat(
          ethers.formatUnits(tokenBalance, 18)
        ).toLocaleString("en-US", {
          minimumFractionDigits:
            parseFloat(ethers.formatUnits(tokenBalance, 18)) < 1000 ? 2 : 0,
          maximumFractionDigits:
            parseFloat(ethers.formatUnits(tokenBalance, 18)) < 1000 ? 2 : 0,
        });

        setBalanceOut(tokenBalanceFormatted);
      } catch (error) {
        console.error("Error fetching balances:", error);
        setErrorMessage("Failed to fetch balances.");
      }
    };

    fetchBalances();
  }, []);

  const processSwapChange = (
    amount,
    token,
    balanceOutSwap,
    tokenInAddress,
    tokenOutAddress
  ) => {
    setInputChanged(true);
    const newValue = amount;
    setAmountInUnformatted(newValue);
    const cleanAndFormattedValue = cleanAndFormatNumber(newValue);
    const cleanedValue = cleanNumber(newValue);

    if (inputField === 1) {
      setAmountIn(cleanAndFormattedValue);
      setAmountExecutableIn(cleanedValue);
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        handleInputChange(
          cleanedValue,
          true,
          token,
          balanceOutSwap,
          tokenInAddress,
          tokenOutAddress
        );
      }, 600);
    }
  };

  let debounceTimeout;

  const handleCustomInputChange =
    (balanceOutSwap, tokenInAddress, tokenOutAddress) => (key) => {
      setInputChanged(true);
      setSwapReview(true);
      let newValue = amountIn;
      if (key === "backspace") {
        newValue = newValue.slice(0, -1);
      } else if (key === ".") {
        if (!newValue.includes(".")) {
          newValue += key;
        }
      } else if (!isNaN(key)) {
        newValue += key;
      }
      setAmountInUnformatted(newValue);
      const cleanAndFormattedValue = cleanAndFormatNumber(newValue);
      const cleanedValue = cleanNumber(newValue);

      if (inputField === 1) {
        setAmountIn(cleanAndFormattedValue);
        setAmountExecutableIn(cleanedValue);
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          handleInputChange(
            cleanedValue,
            true,
            tokenIn,
            balanceOutSwap,
            tokenInAddress,
            tokenOutAddress
          );
        }, 600);
      }
    };

  function cleanAndFormatNumber(value) {
    const cleanedString = value.replace(/[^0-9.]/g, "");
    const decimalCount = (cleanedString.match(/\./g) || []).length;
    if (decimalCount > 1) {
      return "0";
    }
    if (cleanedString === "" || cleanedString === ".") {
      return "0";
    }
    const cleanedNumber = parseFloat(cleanedString);
    if (isNaN(cleanedNumber)) {
      return "0";
    }
    const [integerPart, fractionalPart] = cleanedString.split(".");

    const formattedInteger = parseInt(integerPart, 10).toLocaleString("en-US");
    return fractionalPart !== undefined
      ? `${formattedInteger}.${fractionalPart}`
      : formattedInteger;
  }

  function cleanNumber(value) {
    const cleanedString = value.replace(/[^0-9.]/g, "");
    const decimalCount = (cleanedString.match(/\./g) || []).length;
    if (decimalCount > 1 || cleanedString === "" || cleanedString === ".") {
      return "0";
    }
    const cleanedNumber = parseFloat(cleanedString);
    if (isNaN(cleanedNumber)) {
      return "0";
    }
    return cleanedString;
  }

  const handleInputChange = async (
    value,
    isTokenIn,
    tokenSymbol,
    balanceOutSwap,
    tokenInAddress,
    tokenOutAddress
  ) => {
    setErrorMessage("");

    try {
      if (isTokenIn) {
        if (value === "0") {
          setAmountOut(value);
          setAmountOutUsd(value);
          setAmountInUsd(value);
          setInputChanged(false);
          return;
        }
        if (!isValidValue(value)) {
          setSwapReview(false);
          setErrorMessage("Amount entered not valid");
          setInputChanged(false);
          return;
        }

        const { gasFee, gasFeeEther } = await calculateGasFee(
          value,
          18,
          provider,
          tokenInAddress,
          tokenOutAddress,
          poolFee
        );

        const outputAmounts = await calculateOutputAmount(
          value,
          18,
          18,
          provider,
          tokenInAddress,
          tokenOutAddress,
          poolFee,
          tokenSymbol
        );

        const fractionDigits = tokenSymbol === "ETH" ? 2 : 6;

        const formattedAmount = Number(
          outputAmounts.readableAdjustedAmount
        ).toLocaleString("en-US", {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        });

        setAmountOut(formattedAmount);
        setAmountExecutableOut(outputAmounts.adjustedAmountOut);

        if (tokenSymbol === "ETH") {
          setAmountOutUsd(outputAmounts.outputValueUsd);
          setAmountInUsd(outputAmounts.inputValueUsd);
        } else {
          setAmountOutUsd(outputAmounts.inputValueUsd);
          setAmountInUsd(outputAmounts.outputValueUsd);
        }

        setGasFee(gasFee);
        const balanceOutSwapClean = parseFloat(
          balanceOutSwap.replace(/,/g, "")
        );

        const errorMessage =
          tokenSymbol === "ETH"
            ? parseFloat(value) + parseFloat(gasFeeEther) >
              parseFloat(balanceOutSwapClean)
              ? "Not enough ETH to swap"
              : null
            : parseFloat(value) > parseFloat(balanceOutSwapClean)
            ? "Not enough tokens to swap"
            : null;

        setErrorMessage(errorMessage);
        setSwapReview(false);
      }
    } catch (error) {
      console.error("Error calculating quote:", error);
    }
    setInputChanged(false);
  };

  const handleSwap = (
    amount,
    token,
    balanceOutSwap,
    tokenInAddressSwap,
    tokenOutAddressSwap
  ) => {
    setTokenIn(tokenOut);
    setTokenOut(tokenIn);
    setTokenInAddress(tokenOutAddress);
    setTokenOutAddress(tokenInAddress);
    setBalanceIn(balanceOut);
    setBalanceOut(balanceIn);
    setAmountIn(amountOut);
    setAmountOut(amountIn);
    setErrorMessage("");
    processSwapChange(
      amount,
      token,
      balanceOutSwap,
      tokenOutAddressSwap,
      tokenInAddressSwap
    );
  };

  return (
    <div className="swap-component-wrapper">
      <div
        className={`swap-component-first-input-container ${
          inputField === 1 ? "active" : ""
        }`}
        onClick={() => setInputField(1)}
      >
        <div className="swap-component-input-token-container">
          <DynamicFontSizeInput value={amountIn} caret={true} />

          <div className="swap-component-token-container">
            {tokenIn === "ETH" ? (
              <Eth className="swap-component-svglogo" />
            ) : (
              <RoomAvatar
                roomId={spaceId}
                imageSrc={avatarUrl}
                size={30}
                borderRadius={99}
              />
            )}
            <span className="swap-component-token-text">{tokenIn}</span>
          </div>
        </div>
        <div className="swap-component-USD-balance-container">
          <span className="swap-component-USD-text">
            {amountInUsd ? `$${amountInUsd}` : ""}
          </span>
          <div>
            <span className="swap-component-USD-text">
              {balanceIn} {tokenIn}
            </span>
          </div>
        </div>
        <div
          className={`swap-component-button ${swapReview ? "active" : ""}`}
          onClick={() => {
            if (!swapReview) {
              handleSwap(
                amountOut,
                tokenOut,
                balanceOut,
                tokenInAddress,
                tokenOutAddress
              );
            }
          }}
        >
          <ArrowDown className="arrow-down-svglogo" />
        </div>
      </div>
      <div
        className={`swap-component-secondary-input-container ${
          inputField === 2 ? "active" : ""
        }`}
      >
        <div className="swap-component-input-token-container">
          <DynamicFontSizeInput
            value={amountOut}
            caret={false}
            inputChanged={inputChanged}
          />

          <div className="swap-component-token-container">
            {tokenOut === "ETH" ? (
              <Eth className="swap-component-svglogo" />
            ) : (
              <RoomAvatar
                roomId={spaceId}
                imageSrc={avatarUrl}
                size={30}
                borderRadius={99}
              />
            )}
            <span className="swap-component-token-text">{tokenOut}</span>
          </div>
        </div>
        <div className="swap-component-USD-balance-container">
          <span className="swap-component-USD-text">
            {amountOutUsd ? `$${amountOutUsd}` : ""}
          </span>
          <div>
            <span className="swap-component-USD-text">
              {balanceOut} {tokenOut}
            </span>
          </div>
        </div>
      </div>
      <div className="swap-component-error-gas">
        {amountIn !== "0" && gasFee && !errorMessage && (
          <div className="swap-component-gas-message">
            <Gas className="gas-svglogo" />
            <span className="swap-component-gas-text">{gasFee} USD</span>
          </div>
        )}
        {amountIn !== "0" && errorMessage && (
          <div className="swap-component-error-message">
            <Warning className="warning-svglogo" />
            <span className="swap-component-warning-text">{errorMessage} </span>
          </div>
        )}
      </div>

      <div className="swap-component-keyboard-review">
        <CustomKeyboard
          onInput={handleCustomInputChange(
            balanceIn,
            tokenInAddress,
            tokenOutAddress
          )}
        />
        <div
          className={`swap-component-review-button ${
            isEnabled ? "enabled" : ""
          }`}
          onClick={() => isEnabled && setIsSwapReviewModalOpen(true)}
        >
          <span
            className={`swap-component-review-text ${
              isEnabled ? "enabled" : ""
            }`}
          >
            Review
          </span>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        shouldResize={true}
      >
        <SwapSettings
          afterOptionSelect={() => setIsModalOpen(false)}
          initialSlippage={slippageTolerance}
          onSlippageChange={handleSlippageChange}
        />
      </Modal>
      <Modal
        isOpen={isSwapReviewModalOpen}
        onClose={() => setIsSwapReviewModalOpen(false)}
      >
        <SwapReview
          tokenIn={tokenIn}
          amountOut={amountOut}
          amountIn={amountIn}
          amountInUnformatted={amountInUnformatted}
          tokenInAddress={tokenInAddress}
          amountInExecutable={amountExecutableIn}
          amountInUsd={amountInUsd}
          tokenOut={tokenOut}
          tokenOutAddress={tokenOutAddress}
          amountOutExecutable={amountExecutableOut}
          amountOutUsd={amountOutUsd}
          gasFee={gasFee}
          slippageTolerance={slippageTolerance}
          deadline={Date.now() + 60 * 1000}
          walletAddress={address}
          poolAddress={liquidityData.poolAddress}
          provider={provider}
          afterOptionSelect={() => setIsSwapReviewModalOpen(false)}
          avatarUrl={avatarUrl}
          spaceId={spaceId}
          poolFee={poolFee}
        />
      </Modal>
    </div>
  );
};

export default Swap;
